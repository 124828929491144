<template>
  <div v-loading="loading" class="wrapper">
    <!-- <div class="text-alin">
      <a class="link-a page_menu_item" href="/莲湖.apk" download="智慧养老APP"> <img style="width:70px;height:70px"
          src="@/assets/images/appDownloadBtn.png" alt="" /><span>智慧养老APP</span></a>
    </div> -->
    <el-container>
      <el-main>
        <a v-for="(item, index) in menuList" :key="index" v-permission="item.name" class="menu" @click="handleClick(item)">
          <img class="image" :src="item.src" alt="">
          <span class="text">{{ item.label }}</span>
        </a>
      </el-main>
      <el-footer>
        <p>&copy; All Rights Reserved. 版权所有</p>
        <p>技术支持：赵先生 &nbsp; 联系电话：15389282601</p>
      </el-footer>
    </el-container>
  </div>
</template>
<script>

import { mapMutations } from 'vuex'
import { showNotification } from '@/utils'
import { getChildName } from '@/utils/menu'
import { checkPermission } from '../../../utils'

export default {
  name: 'MyHome',
  data() {
    return {
      loading: false,
      menuList: [
        {
          name: 'SmartSeniorCareSupervisionPlatform',
          label: '智慧养老监管平台',
          src: require('@/assets/images/s1.png'),
          menu: '0',
          detection: 'menuAll'
        },
        {
          name: 'DayCareCenterManagementPlatform',
          label: '日间照料中心管理平台',
          src: require('@/assets/images/s2.png'),
          menu: '1',
          data: { name: 'SumCenter' }
        },
        {
          name: 'SeniorMealManagementPlatform',
          label: '老年餐管理平台',
          src: require('@/assets/images/s3.png'),
          menu: '2',
          detection: 'menuTwo'
        },
        // {
        //   label: '适老化机构管理云平台',
        //   src: require('@/assets/images/s4.png'),
        //   menu: '3',
        //   data: { name: 'ChgCompany' }
        // },
        {
          name: 'DataFlash', // 根据【智慧养老监控平台】是否存在【数据快报】菜单显示
          label: '家庭床位管理云平台',
          src: require('@/assets/images/s5.png'),
          // href: 'https://www.yongaimujia.com/index',
          // menu: '4',
          // data: { name: 'BedCompany' },
          menu: '15',
          data: { name: 'DataFlash' }
        },
        // {
        //   label: '家庭巡视管理云平台',
        //   src: require('@/assets/images/s6.png'),
        //   menu: '5',
        //   data: { name: 'ServeOrgs' }
        // },
        {
          name: 'CallCenterManagementCloudPlatform',
          label: '呼叫中心管理云平台',
          src: require('@/assets/images/s7.png'),
          menu: '6',
          detection: 'menuSix'
        },
        // {
        //   label: '养老机构业务管理云平台',
        //   src: require('@/assets/images/s8.png'),
        //   menu: '7',
        //   data: { name: 'DataCenter' }
        // },
        // {
        //   label: '评估机构业务管理云平台',
        //   src: require('@/assets/images/s9.png'),
        //   menu: '8',
        //   data: { name: 'EvaluationAgency' }
        // },
        {
          name: 'PersonRecord', // 根据【智慧养老监控平台】是否存在【养老顾问服务报告】菜单显示
          label: '养老顾问服务报告',
          src: require('@/assets/images/icon13.png'),
          menu: '15',
          data: { name: 'PersonRecord' }
        },
        {
          name: 'PersonAssess', // 根据【智慧养老监控平台】是否存在【人员评估】菜单显示
          label: '人员评估',
          src: require('@/assets/images/icon11.png'),
          menu: '15',
          data: { name: 'PersonAssess' }
        },
        {
          name: 'OldChange', // 根据【智慧养老监控平台】是否存在【适老化改造】菜单显示
          label: '适老化改造',
          src: require('@/assets/images/icon12.png'),
          menu: '15',
          data: { name: 'OldChange' }
        },
        {
          name: 'BigDataManagementCloudPlatform',
          label: '大数据管理云平台',
          src: require('@/assets/images/s11.png'),
          href: 'index2.html',
          menu: '10'
          // data: { name: 'DictType' }
        },
        // {
        //   label: '志愿者管理系统',
        //   src: require('@/assets/images/s8.png'),
        //   menu: '10',
        //   data: { name: 'VolunteerInfo' }
        // },
        {
          name: 'SystemManagementPlatform',
          label: '系统管理',
          src: require('@/assets/images/s10.png'),
          menu: '9',
          detection: 'menuNine'
        },
        {
          name: 'OldHomeManagementPlatform',
          label: '养老院管理系统',
          src: require('@/assets/images/s100.png'),
          //redirect:'https://www.lexiangyl.com/newyl?token='+sessionStorage.token
          redirect:'http://localhost:3000/#/user/login?token='+sessionStorage.token
    
        },
        {
          label: '消防宣传',
          src: require('@/assets/images/s21.png'),
          open: 'https://www.bilibili.com/video/BV1uo4y137vi/?buvid=XU793087426CA59A590587BFFD23CE823439D&is_story_h5=false&mid=taQ5V3N4rv%2BjvqGOabMQTA%3D%3D&p=1&plat_id=114&share_from=ugc&share_medium=android&share_plat=android&share_session_id=1c3f8e5d-16e8-47dd-ba2f-b53efb8bf016&share_source=WEIXIN&share_tag=s_i&timestamp=1684982645&unique_k=xViHkcS&up_id=690088739'
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['goToPage']),

    handleClick(item) {
      this.loading = true
      if ('open' in item && item['open']) { // 打开新页面
        window.open(item.open)
        this.loading = false
      }else if ('redirect' in item && item['redirect']){
        window.location.href=item.redirect
        this.loading = false
      } else if ('href' in item && item['href']) { // 跳转页面
        sessionStorage.menu = item.menu
        window.location.href = item.href
      } else if ('data' in item && item['data']) { // 跳转路由
        sessionStorage.menu = item.menu
        this.goToPage(item.data)
      } else if ('detection' in item && item['detection']) { // 检测有权限路由并跳转
        getChildName(item['detection']).then(res => {
          checkPermission(res).then(res => {
            if (res) {
              sessionStorage.menu = item.menu
            } else {
              showNotification('error', '权限不足，请重新登录或联系管理员')
            }
            this.goToPage({ name: res || 'Login' })
          }).catch(() => {
            this.goToPage({ name: 'Login' })
            showNotification('error', '权限不足，请重新登录或联系管理员')
          })
        })
      }
      else { // 无法识别
        this.loading = false
        showNotification('error', '系统异常，请联系管理员')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100%;
    height: 100vh;
    background-image: url('../../../assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: auto;

    .el-container {
      display: flex;
      align-items: center;

      .el-main {
        max-width: 1280px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;

        .menu {
          width: 200px;
          margin: 20px 0;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          border-radius: 20px;
          text-decoration: none;

          .image {
            width: 80px;
          }

          .text {
            margin-top: 10px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .menu:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      .el-footer {
        text-align: center;
        color: #fff;
        font-size: 14px;
      }
    }
  }

  .text-alin {
    position: absolute;
    // width: 100%;
    // height: 90%;
    top: 50%;
    left: 90%;
  }
</style>
